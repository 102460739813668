const propiedades = [
  {
    id: 1,
    nombre: 'Poste Exclusivo',
    value: 'exclusivo',
  },
  {
    id: 2,
    nombre: 'Poste EEGSA',
    value: 'eegsa',
  },
]

const tiposPoste = [
  {
    id: 1,
    nombre: 'Madera',
    value: 'madera',
  },
  {
    id: 2,
    nombre: 'Concreto',
    value: 'concreto',
  },
  {
    id: 3,
    nombre: 'Metal',
    value: 'metal',
  },
]

const tamañoPoste = [
  {
    id: 1,
    nombre: '25 Pies',
    value: '25',
  },
  {
    id: 2,
    nombre: '30 Pies',
    value: '30',
  },
  {
    id: 3,
    nombre: '35 Pies',
    value: '35',
  },
  {
    id: 4,
    nombre: '40 Pies',
    value: '40',
  },
]

const observacionesPoste = [
  {
    id: 1,
    nombre: 'Tierra Fisica',
    value: 'tierra',
  },
  {
    id: 2,
    nombre: 'Poste Buen Estado',
    value: 'buenEstado',
  },
  {
    id: 3,
    nombre: 'Poste Mal Estado',
    value: 'malEstado',
  },
]

const tiposBrazo = [
  {
    id: 1,
    nombre: 'HOMOLOGADO',
    value: 'homologado',
  },
  {
    id: 2,
    nombre: 'HECHIZO',
    value: 'hechizo',
  },
  {
    id: 3,
    nombre: 'OTROS',
    value: 'otro',
  },
]

const tamañoBrazo = [
  {
    id: 1,
    nombre: 'Corto',
    value: 2,
  },
  {
    id: 2,
    nombre: '4',
    value: 4,
  },
  {
    id: 3,
    nombre: '6',
    value: 6,
  },
  {
    id: 4,
    nombre: '8',
    value: 8,
  },
  {
    id: 5,
    nombre: '12',
    value: 12,
  },
]

const estadoBrazo = [
  {
    id: 1,
    nombre: 'BUENO',
    value: 'bueno',
  },
  {
    id: 2,
    nombre: 'MALO',
    value: 'malo',
  },
]

const tecnologiasLampara = [
  {
    id: 1,
    nombre: 'Led',
    value: 'led',
  },
  {
    id: 2,
    nombre: 'Sodio',
    value: 'sodio',
  },
  {
    id: 3,
    nombre: 'Solar',
    value: 'solar',
  },
]

const estadoLampara = [
  {
    id: 1,
    nombre: 'Buen Estado',
    value: 'bueno',
  },
  {
    id: 2,
    nombre: 'Mal Estado',
    value: 'malo',
  },
  {
    id: 3,
    nombre: 'Falla',
    value: 'falla',
  },
]

const estadoCable = [
  {
    id: 1,
    nombre: 'Buen Estado',
    value: 'buenEstado',
  },
  {
    id: 2,
    nombre: 'Mal Estado',
    value: 'malEstado',
  },
]

const tipoHerraje = [
  {
    id: 1,
    nombre: 'PERNO',
    value: 'perno',
  },
  {
    id: 2,
    nombre: 'ABRAZADERA',
    value: 'abrazadera',
  },
]

const dispositivosLampara = [
  {
    id: 1,
    nombre: 'LÁMPARA',
    value: 'lampara',
  },
  {
    id: 2,
    nombre: 'REFLECTOR',
    value: 'reflector',
  },
  {
    id: 3,
    nombre: 'OTRO',
    value: 'otro',
  },
]

const angulosInstalacion = [
  {
    id: 1,
    nombre: '0',
    value: 0,
  },
  {
    id: 2,
    nombre: '5',
    value: 5,
  },
  {
    id: 3,
    nombre: '10',
    value: 10,
  },
  {
    id: 4,
    nombre: '15',
    value: 15,
  },
  {
    id: 5,
    nombre: '-5',
    value: -5,
  },
  {
    id: 6,
    nombre: '-10',
    value: -10,
  },
  {
    id: 7,
    nombre: '-15',
    value: -15,
  },
]

const tiposTrabajo = [
  {
    id: 1,
    nombre: 'DIURNO',
    value: 'DIURNO',
  },
  {
    id: 2,
    nombre: 'NOCTURNO',
    value: 'NOCTURNO',
  },
]

// eslint-disable-next-line import/prefer-default-export
export function getListadoDetalle(tipo) {
  if (tipo === 1) return propiedades
  if (tipo === 2) return tiposPoste
  if (tipo === 3) return tamañoPoste
  if (tipo === 4) return observacionesPoste
  if (tipo === 5) return tiposBrazo
  if (tipo === 6) return tamañoBrazo
  if (tipo === 7) return estadoBrazo
  if (tipo === 8) return tecnologiasLampara
  if (tipo === 9) return estadoLampara
  if (tipo === 10) return estadoCable
  if (tipo === 11) return tipoHerraje
  if (tipo === 12) return dispositivosLampara
  if (tipo === 13) return angulosInstalacion
  if (tipo === 14) return tiposTrabajo
  return []
}
