import RequestAdmin from '@/utils/request'
import config from '@/utils/config'
import { getPreciosByCatalogo } from '@/utils/catalogos'

const url = `${config.URL_BASE}migracion-luminarias`

export const tiposTrabajos = {
  lampara: 'LÁMPARA',
  brazo: 'BRAZO',
  poste: 'POSTE',
  otrosTrabajos: 'OTROS TRABAJOS',
  conexion: 'CONEXIÓN',
}
export function getDefaultBodyMigracion(poste, usuario) {
  return {
    companyId: usuario.company.id,
    numeroPoste: poste.numeroPoste,
    idPuntoIluminacion: poste.id,
    estado: 'INGRESADA',
    fechaCreacion: new Date(),
    tracking: [
      {
        estado: 'INGRESADA',
        horaFecha: new Date(),
        usuario: {
          id: usuario.id,
          nombre: `${usuario.firstName} ${usuario.firstSurname}`,
        },
      },
    ],
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateMigracion(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (body.localidad === null) {
      // eslint-disable-next-line no-param-reassign
      delete body.localidad
    }
    if (body.usuario === null) {
      // eslint-disable-next-line no-param-reassign
      delete body.usuario
    }
    if (tipo === 1) return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en actualizar Migración', err)
    return null
  }
}

export async function countMigracionesFilter(filter) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en obtener Migraciones', err)
    return null
  }
}

export async function getMigraciones(filter, puntos = false) {
  try {
    const request = new RequestAdmin()
    return await request.executeGet(`${url}?filter=${JSON.stringify(filter)}&puntos=${puntos}`)
  } catch (err) {
    console.error('Error en obtener Migraciones', err)
    return null
  }
}

export async function getMigracionesSinDetalle(filter) {
  try {
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/light-data?filter=${JSON.stringify(filter)}`)
  } catch (err) {
    console.error('Error en obtener Migraciones', err)
    return null
  }
}

export async function getMigracion(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Migración', err)
    return null
  }
}

export async function reporteAccesoriosRequeridos(body) {
  try {
    const request = new RequestAdmin()
    return request.executePostPutPatch(`${url}/reporte`, body, 'POST')
  } catch (err) {
    console.error('Error en obtener reporte de accesorios requeridos', err)
    return null
  }
}

export async function getMigracionesCompanyStatus(
  company,
  estado,
  arrayId = null,
) {
  const filtro = {
    where: {
      companyId: company,
      estado,
    },
  }
  filtro.fields = {
    id: true,
    tracking: true,
    estado: true,
  }

  let items = []

  if (arrayId) {
    filtro.where.id = { inq: arrayId }
    items = await getMigraciones(filtro)
  } else {
    const total = await countMigracionesFilter(filtro.where)
    let inicio = 0
    const limit = 4000
    for (inicio = 0; inicio <= total.count; inicio += limit) {
      filtro.skip = inicio
      filtro.limit = limit

      try {
        // eslint-disable-next-line no-await-in-loop
        const postes = await getMigraciones(filtro)

        if (postes && postes.length > 0) {
          items = items.concat(postes)
        }
      } catch (error) {
        console.error('Error al obtener postes:', error)
        break
      }
    }
  }

  return items
}

async function actualizarPreciosTrabajos(trabajos) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < trabajos.length; i++) {
    const trabajo = trabajos[i]
    // eslint-disable-next-line no-await-in-loop
    const preciosActualizados = await getPreciosByCatalogo(trabajo.tablaOrigen, trabajo.id)
    if (preciosActualizados && preciosActualizados.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      trabajo.precio = preciosActualizados[preciosActualizados.length - 1]
    } else {
      console.log(`No se encontraron precios actualizados para el trabajo "${trabajo.nombre}"`)
    }
  }
}

/* FUNCIÓN PARA OBTENER LOS TRABAJOS DE UNA MIGRACIÓN */
export async function getTrabajosMigracion(migracion, trabajos) {
  // Actualización de los precios de los trabajos
  let objTrabajos

  await actualizarPreciosTrabajos(migracion.levantamientoPunto.brazo.trabajos)
  await actualizarPreciosTrabajos(migracion.levantamientoPunto.conexion.trabajos)
  await actualizarPreciosTrabajos(migracion.levantamientoPunto.lampara.trabajos)
  await actualizarPreciosTrabajos(migracion.levantamientoPunto.otrosTrabajos)
  await actualizarPreciosTrabajos(migracion.levantamientoPunto.poste.trabajos)

  const mapeoMigracion = migracion.levantamientoPunto
  const cambioLamparaTrabajo = trabajos.find(trabajo => trabajo.nombre === 'CAMBIO DE LÁMPARA')
  if (cambioLamparaTrabajo) {
    const lamparaTrabajos = mapeoMigracion.lampara.trabajos.map(trabajo => ({
      ...trabajo,
      datosLampara: {
        anguloInstalacion: mapeoMigracion.lampara.anguloInstalacion ? mapeoMigracion.lampara.anguloInstalacion : {},
        clase: mapeoMigracion.lampara.clase ? mapeoMigracion.lampara.clase : {},
        dispositivo: mapeoMigracion.lampara.dispositivo ? mapeoMigracion.lampara.dispositivo : {},
        lamparaInstalar: mapeoMigracion.lampara.lamparaInstalar ? mapeoMigracion.lampara.lamparaInstalar : {},
        tipo: mapeoMigracion.lampara.tipo ? mapeoMigracion.lampara.tipo : {},
      },
      seguimiento: [],
    }))
    lamparaTrabajos.push({
      ...cambioLamparaTrabajo,
      seguimiento: [],
    })

    objTrabajos = {
      lampara: {
        trabajos: lamparaTrabajos,
      },
      brazo: {
        trabajos: mapeoMigracion.brazo.trabajos.map(trabajo => ({
          ...trabajo,
          datosBrazo: {
            brazoInstalar: mapeoMigracion.brazo.brazoInstalar ? mapeoMigracion.brazo.brazoInstalar : {},
            estado: mapeoMigracion.brazo.estado ? mapeoMigracion.brazo.estado : {},
            herrajeInstalar: mapeoMigracion.brazo.herrajeInstalar ? mapeoMigracion.brazo.herrajeInstalar : {},
            tamano: mapeoMigracion.brazo.tamano ? mapeoMigracion.brazo.tamano : {},
            tipo: mapeoMigracion.brazo.tipo ? mapeoMigracion.brazo.tipo : {},
            tipoHerraje: mapeoMigracion.brazo.tipoHerraje ? mapeoMigracion.brazo.tipoHerraje : {},
          },
          seguimiento: [],
        })),
      },
      conexion: {
        trabajos: mapeoMigracion.conexion.trabajos.map(trabajo => ({
          ...trabajo,
          datosConexion: {
            cantidadTriplex: mapeoMigracion.conexion.cantidadTriplex ? mapeoMigracion.conexion.cantidadTriplex : '',
            tipo: mapeoMigracion.conexion.tipo ? mapeoMigracion.conexion.tipo : {},
            triplex: mapeoMigracion.conexion.triplex ? mapeoMigracion.conexion.triplex : '',
          },
          seguimiento: [],
        })),
      },
      poste: {
        trabajos: mapeoMigracion.poste.trabajos.map(trabajo => ({
          ...trabajo,
          datosPoste: {
            estado: mapeoMigracion.poste.estado ? mapeoMigracion.poste.estado : {},
            numeroPoste: mapeoMigracion.poste.numeroPoste ? mapeoMigracion.poste.numeroPoste : '',
            posteInstalar: mapeoMigracion.poste.posteInstalar ? mapeoMigracion.poste.posteInstalar : {},
            propiedad: mapeoMigracion.poste.propiedad ? mapeoMigracion.poste.propiedad : {},
            tamano: mapeoMigracion.poste.tamano ? mapeoMigracion.poste.tamano : {},
            tipoPoste: mapeoMigracion.poste.tipoPoste ? mapeoMigracion.poste.tipoPoste : {},
          },
          seguimiento: [],
        })),
      },
      otrosTrabajos: {
        trabajos: mapeoMigracion.otrosTrabajos.map(trabajo => ({
          ...trabajo,
          seguimiento: [],
        })),
      },
    }
  }

  return objTrabajos
}
