<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
      v-b-tooltip.hover.v-primary
      title="Descargar Reporte"
      variant="primary"
      class="btn-icon"
      @click="generarExcel(datosLamparaLevantamiento, datosPosteLevantamiento, datosBrazoLevantamiento, datosHerrajeLevantamiento, datosConexionLevantamiento)"
    >
      <feather-icon
        size="18"
        icon="DownloadIcon"
      />
      {{ textButton }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
  },
  props: {
    datosLamparaLevantamiento: {
      type: Array,
      default: () => [],
    },
    datosPosteLevantamiento: {
      type: Array,
      default: () => [],
    },
    datosBrazoLevantamiento: {
      type: Array,
      default: () => [],
    },
    datosHerrajeLevantamiento: {
      type: Array,
      default: () => [],
    },
    datosConexionLevantamiento: {
      type: Array,
      default: () => [],
    },
    datosAdaptadoresRequeridos: {
      type: Number,
      required: true,
    },
    textButton: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      rows: [],
    }
  },
  methods: {
    recalcularTotal(item) {
      let total = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const row of item.items) {
        total += row.precio ? row.precio : 0
      }
      return total
    },
    async generarExcel(datosLampara, datosPoste, datosBrazo, datosHerraje, datosConexion) {
      // eslint-disable-next-line global-require,no-shadow
      const xl = require('excel4node')
      const wb = new xl.Workbook()
      const ws = wb.addWorksheet('Hoja 1')

      const styleTitulo = wb.createStyle({
        font: {
          bold: true,
          size: 20,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleTituloSecundario = wb.createStyle({
        font: {
          bold: true,
          size: 13,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleSubtitulo = wb.createStyle({
        font: {
          bold: true,
          size: 10,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
      })
      const styleValores = wb.createStyle({
        font: {
          bold: true,
          size: 10,
          color: '#ffffff',
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#0070c0',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
          width: 15,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleCeldasColor = wb.createStyle({
        font: {
          size: 10,
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: '#92cddc',
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })
      const styleCeldas = wb.createStyle({
        font: {
          size: 10,
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { auto: 1 } },
          left: { style: 'thin', color: { auto: 1 } },
          bottom: { style: 'thin', color: { auto: 1 } },
          right: { style: 'thin', color: { auto: 1 } },
        },
      })

      ws.cell(1, 1, 2, 15, true).string('ALUMBRADO PUBLICO DE MIXCO, S.A').style(styleTitulo)
      ws.cell(3, 1, 3, 15, true).string('REPORTE DE ACCESORIOS REQUERIDOS').style(styleTituloSecundario)
      ws.cell(4, 1, 4, 15, true).string('REPORTE GENERAL DE REPORTE DE ACCESORIOS REQUERIDOS').style(styleSubtitulo)

      // <!---------Tabla Lámpara ---------------->
      ws.cell(6, 1, 6, 2, true).string('Datos Lámpara').style(styleValores)
      ws.cell(7, 1).string('Nombre').style(styleValores)
      ws.cell(7, 2).string('Total').style(styleValores)

      let filaLampara = 8
      // eslint-disable-next-line no-restricted-syntax
      for await (const datos of datosLampara) {
        ws.cell(filaLampara, 1).string(datos.nombre).style(styleCeldas)
        ws.cell(filaLampara, 2).number(datos.total).style(styleCeldasColor)

        // eslint-disable-next-line no-plusplus
        filaLampara++
      }
      let sumaTotalLampara = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const datos of datosLampara) {
        sumaTotalLampara += datos.total
      }
      let filaSumaLampara = 8
      filaSumaLampara += datosLampara.length
      ws.cell(filaSumaLampara, 1).string('Suma total').style(styleValores)
      ws.cell(filaSumaLampara, 2).number(sumaTotalLampara).style(styleCeldas)

      // <!---------Fin Tabla ---------------->

      // <!---------Tabla Poste---------------->
      ws.cell(6, 4, 6, 5, true).string('Datos Poste').style(styleValores)
      ws.cell(7, 4).string('Nombre').style(styleValores)
      ws.cell(7, 5).string('Total').style(styleValores)

      let filaPoste = 8
      // eslint-disable-next-line no-restricted-syntax
      for await (const datos of datosPoste) {
        ws.cell(filaPoste, 4).string(datos.nombre === null ? 'NO REQUERIDO' : datos.nombre).style(styleCeldas)
        ws.cell(filaPoste, 5).number(datos.total).style(styleCeldasColor)

        // eslint-disable-next-line no-plusplus
        filaPoste++
      }

      let sumaTotalPoste = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const datos of datosPoste) {
        sumaTotalPoste += datos.total
      }
      let filaSumaPoste = 8
      filaSumaPoste += datosPoste.length
      ws.cell(filaSumaPoste, 4).string('Suma total').style(styleValores)
      ws.cell(filaSumaPoste, 5).number(sumaTotalPoste).style(styleCeldas)

      // <!---------Fin Tabla ---------------->

      // <!---------Tabla Brazo---------------->
      ws.cell(6, 7, 6, 8, true).string('Datos Brazo').style(styleValores)
      ws.cell(7, 7).string('Nombre').style(styleValores)
      ws.cell(7, 8).string('Total').style(styleValores)

      let filaBrazo = 8
      // eslint-disable-next-line no-restricted-syntax
      for await (const datos of datosBrazo) {
        ws.cell(filaBrazo, 7).string(datos.nombre === null ? 'NO REQUERIDO' : datos.nombre).style(styleCeldas)
        ws.cell(filaBrazo, 8).number(datos.total).style(styleCeldasColor)

        // eslint-disable-next-line no-plusplus
        filaBrazo++
      }

      let sumaTotalBrazo = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const datos of datosBrazo) {
        sumaTotalBrazo += datos.total
      }
      let filaSumaBrazo = 8
      filaSumaBrazo += datosBrazo.length
      ws.cell(filaSumaBrazo, 7).string('Suma total').style(styleValores)
      ws.cell(filaSumaBrazo, 8).number(sumaTotalBrazo).style(styleCeldas)

      // <!---------Fin Tabla ---------------->

      // <!---------Tabla Herraje---------------->
      ws.cell(6, 10, 6, 11, true).string('Datos Herraje').style(styleValores)
      ws.cell(7, 10).string('Nombre').style(styleValores)
      ws.cell(7, 11).string('Total').style(styleValores)

      let filaHerraje = 8
      // eslint-disable-next-line no-restricted-syntax
      for await (const datos of datosHerraje) {
        ws.cell(filaHerraje, 10).string(datos.nombre === null ? 'NO REQUERIDO' : datos.nombre).style(styleCeldas)
        ws.cell(filaHerraje, 11).number(datos.total).style(styleCeldasColor)

        // eslint-disable-next-line no-plusplus
        filaHerraje++
      }

      let sumaTotalHerraje = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const datos of datosHerraje) {
        sumaTotalHerraje += datos.total
      }
      let filaSumaHerraje = 8
      filaSumaHerraje += datosHerraje.length
      ws.cell(filaSumaHerraje, 10).string('Suma total').style(styleValores)
      ws.cell(filaSumaHerraje, 11).number(sumaTotalHerraje).style(styleCeldas)

      // <!---------Fin Tabla ---------------->

      // <!---------Tabla Adaptadores---------------->
      ws.cell(6, 13).string('Datos Adaptadores').style(styleValores)
      ws.cell(7, 13).string('Adaptadores Requeridos').style(styleValores)

      const filaAdaptadores = 8
      // eslint-disable-next-line no-restricted-syntax
      ws.cell(filaAdaptadores, 13).number(this.datosAdaptadoresRequeridos).style(styleCeldas)

      // <!---------Fin Tabla ---------------->

      // <!---------Tabla Conexiones---------------->
      ws.cell(6, 15).string('Datos Conexiones').style(styleValores)
      ws.cell(7, 15).string('Metros de Cable Requeridos').style(styleValores)

      let filaConexion = 8
      // eslint-disable-next-line no-restricted-syntax
      for await (const datos of datosConexion) {
        ws.cell(filaConexion, 15).number(datos.triplex === null ? 0 : datos.triplex).style(styleCeldas)

        // eslint-disable-next-line no-plusplus
        filaConexion++
      }

      // <!---------Fin Tabla ---------------->

      // <!---------Width de las celdas de la 1 a la 13 ---------------->
      ws.column(1).setWidth(40)
      ws.column(2).setWidth(15)
      ws.column(3).setWidth(5)
      ws.column(4).setWidth(20)
      ws.column(5).setWidth(15)
      ws.column(6).setWidth(5)
      ws.column(7).setWidth(20)
      ws.column(8).setWidth(15)
      ws.column(9).setWidth(5)
      ws.column(10).setWidth(25)
      ws.column(11).setWidth(15)
      ws.column(12).setWidth(5)
      ws.column(13).setWidth(20)
      ws.column(14).setWidth(5)
      ws.column(15).setWidth(25)

      // Se puede agregar un Height a las celdas
      // for (let i = 3; i <= 7; i++) {
      //   ws.row(i).setHeight(25)
      // }

      const buffer = await wb.writeToBuffer()
      const string = buffer.toString('base64')
      const linkSource = `data:application/xlsx;base64,${string}`
      const downloadLink = document.createElement('a')
      const fileName = 'REPORTE GENERAL DE REPORTE DE ACCESORIOS REQUERIDOS.xlsx'
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  },
}
</script>
