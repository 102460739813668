import { $themeColors } from '@themeConfig'
// colors
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export const basicChartDonut = {
  series: [0],
  options: {
    grid: {
      show: false,
      padding: {
        left: -15,
        right: -15,
        top: -12,
        bottom: -15,
      },
    },
    colors: [chartColors.primaryColorShade],
    plotOptions: {
      radialBar: {
        hollow: {
          size: '40%',
        },
        track: {
          background: '#e9ecef',
        },
        dataLabels: {
          showOn: 'always',
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
      },
    },
    stroke: {
      lineCap: 'round',
    },
  },
}

const colors = [chartColors.infoColorShade, chartColors.greyColor, chartColors.blueLightColor, chartColors.warningLightColor, chartColors.yellowColor, chartColors.labelColor]

export function doughnutSeries(values, labels, description) {
  /** EJEMPLO DE USO
    const MyComponentConstructor2 = Vue.extend(DoughnutChart)
            const vm2 = new MyComponentConstructor2()
           vm2.data = doughnutSeries(values, ['Pendientes', 'Asigandos', 'En Curso', 'Finalizados'], 'Inspecciones')
            vm2.title = 'Detalle de Inspecciones'
            vm2.total = total
           vm2.subtitle = 'Información del mes en curso'
           vm2.$mount('#grafica_inspecciones')
   */

  const data = []

  for (let i = 0; i < values.length; i++) {
    data.push({
      value: values[i],
      name: labels[i],
    })
  }

  return [
    {
      name: description,
      type: 'pie',
      radius: ['50%', '75%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
      },
      labelLine: {
        show: true,
      },
      color: colors,
      data,
    },
  ]
}

export function chartDonutOptions(labels, total) {
  return {
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '14px',
      fontFamily: 'Montserrat',
    },
    dataLabels: {
      enabled: true,
      formatter(val) {
        // eslint-disable-next-line radix
        return `${parseInt(val)}%`
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '2rem',
              fontFamily: 'Montserrat',
            },
            value: {
              fontSize: '1rem',
              fontFamily: 'Montserrat',
              formatter(val) {
                // eslint-disable-next-line radix
                return `${parseInt(val)}`
              },
            },
            total: {
              show: true,
              fontSize: '1.5rem',
              label: 'Total',
              formatter() {
                return `${total}`
              },
            },
          },
        },
      },
    },
    labels,
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 380,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1.5rem',
                  },
                  value: {
                    fontSize: '1rem',
                  },
                  total: {
                    fontSize: '1.5rem',
                  },
                },
              },
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  }
}

export function polarChartOptions() {
  /* EJEMPLO DE USO
      const options = polarChartOptions()
        const data = polarChartData(['Pendientes', 'Asigandos', 'En Curso', 'Finalizados'], values, 'Inspecciones')
        const MyComponentConstructor = Vue.extend(PolarAreaChart)
        const vm = new MyComponentConstructor()
        vm.chartOptions = options
        vm.data = data
        vm.title = 'Detalle de Inspecciones'
        vm.subtitle = 'Información del mes en curso'
        vm.$mount('#grafica_inspecciones')
   */
  return {
    responsive: true,
    maintainAspectRatio: false,
    responsiveAnimationDuration: 500,
    legend: {
      position: 'right',
      labels: {
        usePointStyle: true,
        padding: 25,
        boxWidth: 10,
        fontColor: chartColors.labelColor,
      },
    },
    tooltips: {
      // Updated default tooltip UI
      shadowOffsetX: 1,
      shadowOffsetY: 1,
      shadowBlur: 8,
      shadowColor: chartColors.tooltipShadow,
      backgroundColor: $themeColors.light,
      titleFontColor: $themeColors.dark,
      bodyFontColor: $themeColors.dark,
    },
    scale: {
      scaleShowLine: true,
      scaleLineWidth: 1,
      ticks: {
        display: false,
        fontColor: chartColors.labelColor,
      },
      reverse: false,
      gridLines: {
        display: false,
      },
    },
    animation: {
      animateRotate: false,
    },
  }
}

export function polarChartData(labels, data, label) {
  return {
    labels,
    datasets: [
      {
        label,
        backgroundColor: [
          chartColors.primaryColorShade,
          chartColors.warningColorShade,
          $themeColors.primary,
          chartColors.infoColorShade,
          chartColors.greyColor,
          chartColors.successColorShade,
        ],
        data,
        borderWidth: 0,
      },
    ],
  }
}

export function barsChartOptions(labels, values, label = true) {
  const options = {
    series: [{
      data: values,
    }],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar',
      },
      colors,
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: !label,
      },
      xaxis: {
        categories: labels,
        labels: {
          show: label,
          style: {
            colors,
            fontSize: '10px',
          },
        },
      },
    },
  }

  return options
}
