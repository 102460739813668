<template>
  <div>
    <b-overlay :show="showOverlay">
      <!-- CONTADORES Y SWITCHS -->
      <!-- FILTROS -->
      <b-card style="margin-top: -13px;">
        <!-- Filter -->
        <table style="margin-top: -15px; margin-bottom: -5px">
          <tr>
            <td style="width: 1%">
              <label>Zona:</label>
              <v-select
                v-model="zona"
                label="name"
                :options="zonas"
                style="width: 18vw;"
                @input="filterLocalidades"
              />
            </td>
            <td style="width: 1%" />
            <td style="width: 30%;">
              <label>Colonia:</label>
              <v-select
                v-model="localidadesSelect"
                label="nombre"
                :options="localidades"
                style="min-width: 30vw"
                multiple
                @input="filterData"
              />
            </td>
            <td style="width: 1%" />
            <td style="width: 15%;">
              <label>Estado: </label>
              <v-select
                v-model="estadoSeleccionado"
                style="min-width: 12vw"
                :options="estados"
                label="nombre"
                @input="estadoSeleccionadoFuncion"
              />
            </td>
            <td style="width: 1%" />
            <td style="width: 1%;">
              <br>
              <reporte-levantamiento-button />
            </td>
            <td
              v-if="rolesAdmin.includes(user.role)"
              style="width: 1%;"
            >
              <br>
              <b-button
                v-b-tooltip.hover.v-primary
                variant="primary"
                placeholder="Ingresar"
                title="Ingresar Luminaria Manualmente"
                style="height: 40px; width: 40px;"
                class="btn-icon"
                @click="ingresoManual"
              >
                <feather-icon
                  size="18"
                  icon="PlusSquareIcon"
                />
              </b-button>
            </td>
            <td
              v-if="rolesAdmin.includes(user.role)"
              style="width: 1%;"
            >
              <br>
              <b-button
                v-b-tooltip.hover.v-success
                variant="success"
                placeholder="Asignar Técnico"
                style="height: 40px; width: 40px;"
                title="Asignar Técnico"
                class="btn-icon"
                @click="asignarTecnico"
              >
                <feather-icon
                  style="margin-top: -3px; margin-right: -4px"
                  size="18"
                  icon="UserCheckIcon"
                />
              </b-button>
            </td>
            <td style="width: 1%;">
              <br>
              <b-button
                v-b-tooltip.hover.v-secondary
                variant="secondary"
                placeholder="Actualizar Ubicación"
                style="height: 40px; width: 40px;"
                title="Actualizar Ubicación"
                class="btn-icon"
                @click="updateMyLocation"
              >
                <feather-icon
                  style="margin-top: -3px; margin-right: -4px"
                  size="18"
                  icon="MapPinIcon"
                />
              </b-button>
            </td>
            <td style="width: 1%;">
              <br>
              <b-button
                v-b-tooltip.hover.v-warning
                variant="warning"
                placeholder="Buscar Punto"
                style="height: 40px; width: 40px;"
                class="btn-icon"
                title="Buscar Punto"
                @click="modalBusqueda = true"
              >
                <feather-icon
                  style="margin-top: -3px; margin-right: -4px"
                  size="18"
                  icon="SearchIcon"
                />
              </b-button>
            </td>
          </tr>
        </table>
        <hr>
        <b-card-actions
          title="CONTADORES DE ESTADO"
          action-collapse
          collapsed
          style="margin-bottom: -15px; text-align: center"
        >
          <div style="display: flex; flex-wrap: wrap; gap: 5px; margin-bottom: -30px;">
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #565656">
                  <center>
                    <h6 style="font-weight: bold">
                      PENDIENTES
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[0] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #1a2429">
                  <center>
                    <h6 style="font-weight: bold">
                      MANUALES
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[1] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #29b13b">
                  <center>
                    <h6 style="font-weight: bold">
                      INSPECCIONADAS
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[4] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #1a8d29">
                  <center>
                    <h6 style="font-weight: bold">
                      ASIGNADAS
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[2] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #ffae26">
                  <center>
                    <h6 style="font-weight: bold">
                      INSTALACIÓN
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[3] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #5b2e0a">
                  <center>
                    <h6 style="font-weight: bold">
                      MIGRADAS
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[5] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
            <div style="flex-grow: 1; flex-basis: 80px">
              <b-overlay :show="loadingContador">
                <b-card style="border: 2px solid #006394">
                  <center>
                    <h6 style="font-weight: bold">
                      FINALIZADAS
                    </h6>
                    <div style="font-weight: bold; font-size: large; margin-top: -5px; margin-bottom: -10px">
                      {{ contadores[6] }}
                    </div>
                  </center>
                </b-card>
              </b-overlay>
            </div>
          </div>
        </b-card-actions>
        <hr>
        <div
          v-if="rolesAdmin.includes(user.role)"
          style="margin-bottom: 5px"
        >
          <table>
            <tr>
              <td style="width: 35%">
                <div class="d-flex">
                  <span
                    class="text-primary"
                    style="font-weight: bold; margin-right: 8px"
                  >
                    Modificar Marcadores
                  </span>
                  <b-form-checkbox
                    v-model="modifyMarkers"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    @change="updateMarkers(items)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="XIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="Edit3Icon" />
                    </span>
                  </b-form-checkbox>
                </div>
              </td>
              <td style="width: 35%">
                <div class="d-flex">
                  <span
                    class="text-primary"
                    style="font-weight: bold; margin-right: 10px"
                  >
                    Habilitar Asignación
                  </span>
                  <b-form-checkbox
                    v-model="enableAsignacion"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    @change="updateMarkers(items)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="XIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="Edit3Icon" />
                    </span>
                  </b-form-checkbox>
                </div>
              </td>
              <td style="width: 30%" />
            </tr>
          </table>
        </div>
      </b-card>
      <!-- MAPA -->
      <div
        id="map"
        class="map-container"
      />
    </b-overlay>
    <div>
      <!--- MODAL INSPECCIÓN --->
      <b-modal
        id="modalInsepccion"
        v-model="showModalInspeccion"
        :no-close-on-backdrop="true"
        hide-footer
        modal-class="modal-primary"
        centered
        title="Levantamiento de Datos"
        size="xl"
        style="max-width: 90% !important;"
      >
        <form-levantamiento
          :punto-seleccionado="puntoLevantamiento"
          :localizacion="userLocation"
          :tecnologias="tecnologias"
          :potencias="potencias"
          :tipos-poste="tiposPoste"
          :herrajes="herrajes"
          :tamano-brazos="tamanoBrazos"
          :tipo-conexiones="tipoConexiones"
          :tipo-lamparas="tiposLamparas"
          :propiedades="propiedades"
          :trabajos="trabajos"
          :tipo-postes-instalacion="tipoPostesInstalacion"
          :metro-triplex="metroTriplex"
          :clases-lampara="clasesLampara"
          :tamaños="tamaños"
          :dispositivos="dispositivos"
          :localidad="localidad"
          :localidades="localidades"
          :origen="'CRECIMIENTO'"
          @cerrar-dialogo-levantamiento="updateState"
          @notificar-cambio="filterData"
        />
      </b-modal>
      <!--- MODAL ASIGNACIÓN --->
      <b-modal
        ref="modal-tecnico"
        v-model="dialogAsigacion"
        title="Asignación de Técnico"
        ok-title="Asignación de Técnico"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        @hidden="tecnicoEncargado = null"
        @ok="handleOk"
      >
        <b-form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-form-group
            label="Seleccione el Técnico Encargado"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="tecnicoEncargado"
              label="nombre"
              :options="tecnicos"
              required
            />
          </b-form-group>
        </b-form>
      </b-modal>
      <!--- MODAL BUSQUEDA --->
      <b-modal
        v-model="modalBusqueda"
        title="Búsqueda de Poste"
        no-close-on-backdrop
        ok-only
        ok-title="Cancel"
        cancel-variant="warning"
        @ok="postesBuscados = []"
      >
        <b-row>
          <b-col cols="10">
            <b-form-group>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="icons-search"
                  v-model="seachQuery"
                  placeholder="Buscar Puntos de Iluminación..."
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="seachDataBusqueda()"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="(item) in postesBuscados"
            :key="item.id"
            cols="12"
          >
            <b-card-group class="mb-0">
              <b-card
                :title="`Punto de Iluminación`"
                class="text-center"
                footer-class="text-muted"
              >
                <b-card-body>
                  <b-row>
                    <b-col cols="3">
                      <h5>No. Poste:</h5>
                      <b-form-input
                        id="orden"
                        :value="item.numeroPoste"
                        readonly
                        style="margin-top: -8px;"
                      />
                    </b-col>

                    <b-col cols="2">
                      <h5>Zona:</h5>
                      <b-form-input
                        id="orden"
                        :value="item.obj_localidad.zona"
                        readonly
                        style="margin-top: -8px;"
                      />
                    </b-col>
                    <b-col cols="6">
                      <h5>Localidad:</h5>
                      <b-form-input
                        id="orden"
                        :value="item.obj_localidad.nombre"
                        readonly
                        style="margin-top: -8px; font-size: 10px;"
                      />
                    </b-col>
                    <b-col cols="1">
                      <b-button
                        v-b-tooltip.hover.v-primary
                        variant="primary"
                        placeholder="Seleccionar el Punto"
                        title="Seleccionar el Punto"
                        style="margin-top: 0.8rem;"
                        @click="seleccionarPunto(item)"
                      >
                        <feather-icon icon="CheckIcon" />&nbsp;
                      </b-button>
                    </b-col></b-row>
                </b-card-body>
              </b-card>
            </b-card-group>
            <br>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BModal,
  BButton,
  VBTooltip,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCardGroup,
  BCard,
  BCardBody,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getLocalidades, getLocalidadesFilter } from '@/utils/localizacion'
import {
  getPostesFilter, getPostesWithMigration, updateCreatePoste,
} from '@/utils/postes'
import FormLevantamiento from '@/components/levantamiento/FormLevantamiento.vue'
import { getItem, getlistado } from '@/utils/catalogos'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import { getUsersByCompany } from '@/utils/usuarios'
import config from '@/utils/config'
import {
  updateCreateMigracion,
} from '@/utils/migracionLuminarias'
import ReporteLevantamientoButton from '@/components/levantamiento/reporteLevantamientoButton.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  name: 'MapComponent',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCardActions,
    ReporteLevantamientoButton,
    FormLevantamiento,
    BOverlay,
    BRow,
    BCol,
    vSelect,
    BModal,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCardGroup,
    BCard,
    BCardBody,
    BFormCheckbox,
  },
  data() {
    return {
      todosLosItems: [],
      loadingContador: false,
      contadores: [0, 0, 0, 0, 0, 0, 0], // PENDIENTES, MANUALES, ASIGNADAS, EN PROGRESO, INSPECCIONADAS, MIGRADAS, FINALIZADAS
      watchId: null,
      enableAsignacion: false,
      catalogoName: 'Mapa de Puntos de Iluminación',
      dialogAsigacion: false,
      tecnicoEncargado: null,
      items: [],
      user: JSON.parse(localStorage.getItem('userData')),
      localidadesSelect: null,
      zona: {},
      localidad: null,
      localidades: [],
      tecnologias: [],
      potencias: [],
      tiposPoste: [],
      herrajes: [],
      trabajos: [],
      tamanoBrazos: [],
      tipoConexiones: [],
      tiposLamparas: [],
      propiedades: [],
      tecnicos: [],
      tipoPostesInstalacion: [],
      clasesLampara: [],
      dispositivos: [],
      tamaños: [],
      rows: [],
      map: null,
      mapInitialized: false,
      markers: [],
      itemsSelected: [],
      migraciones: [],
      showOverlay: true,
      showModalInspeccion: false,
      puntoLevantamiento: {},
      metroTriplex: {},
      etapas: [
        { title: 'TODAS', value: '' },
        { title: 'Ingresados', value: 'INGRESADA' },
        { title: 'Asignados', value: 'ASIGNADA' },
        { title: 'En Instalación', value: 'INSTALACION' },
        { title: 'Migrados', value: 'MIGRADA' },
        { title: 'Finalizados', value: 'FINALIZADO' },
      ],
      rolesAdmin: ['ADMINISTRATOR', 'LEVANTAMIENTO'],
      iconos: {
        // eslint-disable-next-line global-require
        persona: require('@/assets/images/focos/persona.png'),
        // eslint-disable-next-line global-require
        pendiente: require('@/assets/images/focos/gray.png'),
        // eslint-disable-next-line global-require
        inspeccionado: require('@/assets/images/focos/green.png'),
        // eslint-disable-next-line global-require
        asignadaGreen: require('@/assets/images/focos/green_a.png'),
        // eslint-disable-next-line global-require
        asignadaPurple: require('@/assets/images/focos/purple_a.png'),
        // eslint-disable-next-line global-require
        instalado: require('@/assets/images/focos/blue.png'),
        // eslint-disable-next-line global-require
        bloqueado: require('@/assets/images/focos/orange.png'),
        // eslint-disable-next-line global-require
        especial: require('@/assets/images/focos/purple.png'),
        // eslint-disable-next-line global-require
        instalacion: require('@/assets/images/focos/yellow.png'),
        // eslint-disable-next-line global-require
        migrada: require('@/assets/images/focos/brown.png'),
      },
      userLocation: {
        lat: 0,
        lng: 0,
      },
      zonas: [],
      // busqueda
      puntoSeleccionado: null,
      seachQuery: '',
      modalBusqueda: false,
      postesBuscados: [],
      allLocalidades: [],
      modifyMarkers: false,
      estadoSeleccionado: '',
      estados: [
        { nombre: 'TODO', value: '' },
        { nombre: 'PENDIENTES', value: 'PENDIENTES' },
        { nombre: 'MANUALES', value: 'MANUALES' },
        { nombre: 'INSPECCIONADAS', value: 'INSPECCIONADAS' },
        { nombre: 'ASIGNADAS', value: 'ASIGNADAS' },
        { nombre: 'INSTALACIÓN', value: 'INSTALACION' },
        { nombre: 'MIGRADAS', value: 'MIGRADAS' },
        { nombre: 'FINALIZADAS', value: 'FINALIZADAS' },
      ],
    }
  },
  watch: {
    items: {
      handler(newVal) {
        this.updateMarkers(newVal)
      },
      deep: true, // Esto asegura que se detecten cambios incluso en objetos anidados dentro del array
    },
  },
  mounted() {
    this.loadGoogleMaps()
    setTimeout(() => {
      this.startWatchingUserPosition()
    }, 1000)
  },
  beforeDestroy() {
    // Asegúrate de detener el seguimiento de la posición al destruir el componente
    if (this.watchId) {
      navigator.geolocation.clearWatch(this.watchId)
    }
  },
  async created() {
    this.eventSource = new EventSource(`${config.URL_BASE}events`)
    this.eventSource.onmessage = event => {
      const data = JSON.parse(event.data)
      this.notificacionCambio(data)
    }

    const zonas = this.user.company.zonas || 10
    for (let i = 1; i <= zonas; i += 1) {
      this.zonas.push({
        id: i,
        name: `ZONA ${i}`,
      })
    }

    // eslint-disable-next-line prefer-destructuring
    this.zona = this.zonas[0]

    await this.cargaInformacion()
    this.tecnicos = await getUsersByCompany(this.user.company.id)
    this.tecnicos.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
  },
  methods: {
    loadGoogleMaps() {
      const script = document.createElement('script')
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBNJljqRscajuPzqop6Vlfi9zsNaslUEi4&callback=initMap&libraries=marker&loading=async'
      script.async = true
      script.defer = true
      window.initMap = this.initMap
      document.head.appendChild(script)
    },
    startWatchingUserPosition() {
      if ('geolocation' in navigator) {
        this.watchId = navigator.geolocation.watchPosition(
          this.handlePositionUpdate,
          this.handleLocationError,
          { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 },
        )
      } else {
        console.error('Geolocalización no soportada por este navegador.')
      }
    },
    handlePositionUpdate(position) {
      const userLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }

      this.userLocation = userLocation

      // Centra el mapa en la nueva ubicación del usuario
      // this.map.setCenter(userLocation)

      // Actualiza o crea un marcador para la ubicación del usuario
      if (this.userMarker) {
        this.userMarker.setPosition(userLocation)
      } else {
        this.userMarker = new google.maps.Marker({
          position: userLocation,
          map: this.map,
        })
      }
    },
    handleLocationError(error) {
      console.error('Error al obtener la ubicación:', error)
    },
    notificacionCambio(event) {
      const { punto, migracion } = event
      const indice = this.items.findIndex(obj => obj.id === punto.id)
      let indiceMigracion = null
      if (migracion.id) {
        indiceMigracion = this.migraciones.findIndex(m => m.id === migracion.id)
      }

      if (indice !== -1) {
        this.items[indice] = punto
      }

      if (indiceMigracion !== -1) {
        this.migraciones[indiceMigracion] = migracion
      } else if (indiceMigracion === -1) {
        this.migraciones.push(migracion)
      }

      this.updateMarkers(this.items)
    },
    async seleccionarPunto(item) {
      const filtro = {
        where: {
          id: item.id,
          company: this.user.company.id,
        },
      }
      const postes = await getPostesWithMigration(filtro, true)
      let poste = item
      if (postes.length !== 0) {
        // eslint-disable-next-line prefer-destructuring
        poste = postes[0]
      }

      this.modalBusqueda = false
      this.seachQuery = ''
      this.zona = this.zonas.find(z => z.id === Number(item.obj_localidad.zona))
      await this.filterLocalidades()
      this.localidadesSelect = this.localidades.filter(localidad => localidad.id === item.obj_localidad.id)
      // eslint-disable-next-line no-param-reassign
      delete item.obj_localidad
      this.items = [poste]
      this.postesBuscados = []
      this.map.setCenter({
        lat: Number(item.latitud),
        lng: Number(item.longitud),
      })
    },
    async seachDataBusqueda() {
      if (this.seachQuery === '') {
        mensajeError('Ingrese un valor de referencia del poste')
        return
      }
      const filter = {
        where: {
          and: [
            { company: this.user.company.id },
            { numeroPoste: { regexp: this.seachQuery } },
          ],
        },
      }
      this.postesBuscados = await getPostesFilter(filter, true, this.allLocalidades)
      if (this.postesBuscados.length === 0) {
        mensajeError('No se encontraron resultados!')
      }
    },
    async filterLocalidades() {
      this.localidadesSelect = null
      if (this.zona === null) {
        this.localidades = []
        this.items = []
        this.estadoSeleccionado = ''
        this.todosLosItems = [...this.items]
        return
      }
      await this.getLocalidades()
    },
    ingresoManual() {
      const punto = {
        municipio: this.user.company.municipio,
        company: this.user.company.id,
        grupo: 12,
        numeroContador: 'N/A',
        redComunicacion: 'N/A',
        tipoLuminaria: '',
        observaciones: '',
        numeroPoste: '',
        calleAvenida: '',
        numeral: '',
        latitud: '',
        longitud: '',
        objGrupo: {
          id: 12,
          nombre: 'G0',
          user: 'ADMIN',
          active: true,
        },
        ingresoManual: true,
      }
      this.levantamientoDatos(punto)
    },
    asignarTecnico() {
      if (this.itemsSelected.length === 0) {
        mensajeError('Debes seleccionar al menos un punto')
        return
      }
      this.dialogAsigacion = true
    },
    checkFormValidity() {
      return this.tecnicoEncargado
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    async handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        mensajeError('Debe de seleccionar un técnico!')
        return
      }
      await this.guardarAsinaciones()
    },
    async guardarAsinaciones() {
      try {
        this.showOverlay = true

        const promises = this.itemsSelected.map(item => {
          const { ticketId } = item.punto.detalleInicial
          const { tracking } = item.punto
          const nuevoObjetoTracking = {
            estado: 'ASIGNADA',
            horaFecha: new Date(),
            usuario: {
              id: this.user.id,
              nombre: `${this.user.firstName} ${this.user.firstSurname}`,
            },
          }
          const bodyMigracion = {
            id: ticketId,
            estado: 'ASIGNADA',
            order: item.selectionOrder,
            usuario: {
              id: this.tecnicoEncargado.id,
              nombre: this.tecnicoEncargado.nombre,
            },
            tracking: [...tracking, nuevoObjetoTracking],
          }
          return updateCreateMigracion(bodyMigracion, 1)
        })

        Promise.all(promises)
          .then(() => {
            mensajeInformativo(
              'Técnico asignado correctamente',
              'Se asignó correctamente el técnico a los puntos seleccionados!',
            )
            // eslint-disable-next-line no-restricted-syntax
            for (const item of this.itemsSelected) {
              const indice = this.items.findIndex(obj => obj.id === item.punto.id)
              if (indice !== -1) {
                this.items[indice].estado = 'ASIGNADA'
              }
            }

            this.itemsSelected = []
            this.updateMarkers(this.items)
          })
          .catch(error => {
            console.error('Error en la ejecución de las migraciones', error)
            mensajeError('Error al asignar técnico, itente más tarde.')
          })
      } catch (err) {
        console.error(`Error en ${this.catalogoName}`, err)
        mensajeError('Error al asignar técnico, itente más tarde.')
      } finally {
        this.showOverlay = false
        this.dialogAsigacion = false
      }
    },
    async updateState(punto) {
      this.showOverlay = true
      this.itemsSelected = []
      const indice = this.items.findIndex(obj => obj.id === punto.id)
      if (indice !== -1) {
        this.items[indice].detalleInicial = punto.detalleInicial
      } else {
        this.items.push(punto)
      }
      this.showModalInspeccion = false
      this.updateMarkers(this.items)
      this.showOverlay = false
    },
    async getLocalidades() {
      const filter = {
        where: {
          and: [
            { idMunicipio: this.user.company.municipio },
            { zona: this.zona.id.toString() },
          ],
        },
      }
      this.localidades = await getLocalidadesFilter(filter)
    },
    async cargaInformacion() {
      try {
        await this.getLocalidades()
        const [
          tecnologias,
          potencias,
          tiposPoste,
          herrajes,
          tamanoBrazos,
          tipoConexiones,
          tiposLamparas,
          propiedades,
          trabajos,
          tipoPostesInstalacion,
          metroTriplex,
          clasesLampara,
          dispositivos,
          tamaños,
          allLocalidades,
          // eslint-disable-next-line no-unused-vars
          migraciones,
        ] = await Promise.all([
          getlistado(4, 'Tecnología Luminarias', false, this.user),
          getlistado(25, 'Potencias', false, this.user),
          getlistado(29, 'Tipos de Poste', false, this.user),
          getlistado(26, 'Herrajes', false, this.user),
          getlistado(14, 'Tipo Brazo', false, this.user),
          getlistado(28, 'Conexiones', false, this.user),
          getlistado(9, 'Tipos de Lámparas', false, this.user),
          getlistado(6, 'Propiedades', false, this.user),
          getlistado(30, 'Trabajos Levantamiento', false, this.user),
          getlistado(1, 'Tipos de Poste', false, this.user),
          getItem(10, 25),
          getlistado(3, 'Clases de Lámpara', false, this.user),
          getlistado(31, 'Dispositivos de Lámpara', false, this.user),
          getlistado(32, 'Tamaños de Accesorios', false, this.user),
          getLocalidades(null, this.user.company.municipio),
        ])

        potencias.sort((a, b) => a.valor - b.valor)

        this.tecnologias = tecnologias
        this.potencias = potencias
        this.tiposPoste = tiposPoste
        this.herrajes = herrajes
        this.tamanoBrazos = tamanoBrazos
        this.tipoConexiones = tipoConexiones
        this.tiposLamparas = tiposLamparas
        this.propiedades = propiedades
        this.trabajos = trabajos
        this.tipoPostesInstalacion = tipoPostesInstalacion
        this.metroTriplex = metroTriplex
        this.clasesLampara = clasesLampara
        this.dispositivos = dispositivos
        this.tamaños = tamaños
        this.allLocalidades = allLocalidades

        this.items = []
        if (this.localidades === null) this.localidades = []
        if (this.localidades.length > 0) {
          this.showOverlay = false
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.localidades = []
        this.items = []
      }
    },
    async loadPostes(array) {
      this.loadingContador = true
      if (array === null) {
        this.items = []
      } else {
        const ids = array.map(localidad => localidad.id)
        const filtro = {
          where: {
            localidad: { inq: ids },
            company: this.user.company.id,
          },
        }
        this.items = await getPostesWithMigration(filtro, true)
      }
      if (this.items === null) this.items = []
      this.estadoSeleccionado = ''
      this.todosLosItems = [...this.items]
      this.loadingContador = false
    },
    async estadoSeleccionadoFuncion(newValue) {
      if (!this.todosLosItems) {
        this.todosLosItems = [...this.items]
      }
      if (!newValue || newValue.value === '') {
        this.items = [...this.todosLosItems]
      } else if (newValue && newValue.nombre === 'PENDIENTES') {
        this.items = this.todosLosItems.filter(item => !item.detalleInicial)
      } else if (newValue && newValue.nombre === 'MANUALES') {
        this.items = this.todosLosItems.filter(item => item.ingresoManual)
      } else if (newValue && newValue.nombre === 'INSPECCIONADAS') {
        this.items = this.todosLosItems.filter(item => item.detalleInicial && item.estado === 'INGRESADA')
      } else if (newValue && newValue.nombre === 'ASIGNADAS') {
        this.items = this.todosLosItems.filter(item => item.estado === 'ASIGNADA')
      } else if (newValue && newValue.value === 'INSTALACION') {
        this.items = this.todosLosItems.filter(item => item.estado === 'INSTALACION')
      } else if (newValue && newValue.value === 'MIGRADAS') {
        this.items = this.todosLosItems.filter(item => item.estado === 'INSTALADA')
      } else if (newValue && newValue.value === 'FINALIZADAS') {
        this.items = this.todosLosItems.filter(item => item.estado === 'FINALIZADO')
      }
    },
    async filterData(newValue) {
      if (newValue.length > 10) {
        newValue.pop()
        this.localidadesSelect = newValue
        this.todosLosItems = []
        this.estadoSeleccionado = ''
        return
      }
      await this.loadPostes(this.localidadesSelect)
      this.todosLosItems = [...this.items]
    },
    updateMarkers(puntos) {
      this.contadores = [0, 0, 0, 0, 0, 0, 0]
      this.markers.forEach(marker => marker?.marker.setMap(null))
      this.markers = []
      puntos.forEach(punto => {
        this.addMarker(punto)
      })
      this.loadingContador = false
    },
    initMap() {
      if (this.mapInitialized) {
        return
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }

            // Inicializa el mapa con la ubicación actual del usuario
            this.map = new google.maps.Map(this.$el.querySelector('#map'), {
              center: currentLocation,
              zoom: 16,
            })

            // Ahora añade los marcadores
            this.items.forEach(punto => this.addMarker(punto))
            this.mapInitialized = true
          },
          () => {
            this.handleLocationError(null)
          },
        )
      } else {
        // El navegador no soporta Geolocalización
        console.error('El navegador no soporta Geolocalización')
      }
    },
    actualizarPosicionPunto(punto) {
      updateCreatePoste(punto, 1)
    },
    generarContenidoInfoWindow(punto, calidadDeseada) {
      const urlImagen = punto.imagenes && punto.imagenes[0] && punto.imagenes[0].url
      const urlImagenConCalidad = `${urlImagen}?quality=${calidadDeseada}`
      const mostrarImagen = this.user.company.enabledReport
      return `
    <div style="max-width: 150px; min-height: 280px">
      <h3 align="center" class="text-primary">N. ${punto.numeroPoste}</h3>
      <p class="text-primary" style="font-weight: bold; margin-top: -5px">Potencia a instalar:</p>
      <p style="margin-top: -10px">${punto.detalleInicial && punto.detalleInicial.lampara && punto.detalleInicial.lampara.lamparaInstalar.nombre}</p>
      <p class="text-primary" style="font-weight: bold; margin-top: -5px">Comentarios:</p>
      <p style="margin-top: -10px">${punto.detalleInicial && punto.detalleInicial.comentariosGenerales}</p>
      <div style="text-align: center; margin-top: -10px">
        ${mostrarImagen ? `<img style="border-radius: 10px" width="130px" height="130px" alt="Foto previsualización punto" src="${urlImagenConCalidad}">`
    : '<span>Foto previsualización</span>'}
      </div>
    </div>
  `
    },
    async addMarker(punto) {
      const { iconUrl, action } = await this.getDataStatus(punto)
      const marker = new google.maps.Marker({
        position: { lat: Number(punto.latitud), lng: Number(punto.longitud) },
        map: this.map,
        title: punto.name,
        icon: iconUrl,
        draggable: this.modifyMarkers,
      })

      if (this.tecnologias.length > 0 && punto.tecnologiaLampara) {
        const tecnologia = this.tecnologias.find(obj => obj.id === punto.tecnologiaLampara)
        if (tecnologia) {
          // eslint-disable-next-line no-param-reassign
          punto.tecnologiaLampara = tecnologia.nombre
        }
      }

      if (this.clasesLampara.length > 0 && punto.modeloLampara) {
        const modelo = this.clasesLampara.find(obj => obj.id === punto.modeloLampara)
        if (modelo) {
          // eslint-disable-next-line no-param-reassign
          punto.modeloLampara = modelo.nombre
        }
      }

      const infoWindow = new google.maps.InfoWindow({
        content: this.generarContenidoInfoWindow(punto, 1),
      })

      infoWindow.addListener('domready', () => {
        const infoWindowElement = document.querySelector('.gm-style-iw')

        if (infoWindowElement) {
          infoWindowElement.addEventListener('dblclick', () => {
            this.levantamientoDatos(punto)
          })
        }
      })

      marker.addListener('click', () => {
        if (action) action()
        else infoWindow.open(this.map, marker)
      })

      marker.addListener('dblclick', () => {
        if (action) action()
        else this.levantamientoDatos(punto)
      })

      marker.addListener('dragend', event => {
        const newPosition = event.latLng
        const updatedPunto = {
          id: punto.id,
          latitud: `${newPosition.lat()}`,
          longitud: `${newPosition.lng()}`,
        }

        // eslint-disable-next-line no-param-reassign
        punto.latitud = newPosition.lat()
        // eslint-disable-next-line no-param-reassign
        punto.longitud = newPosition.lng()

        this.actualizarPosicionPunto(updatedPunto)
      })

      this.markers.push({
        id: punto.id,
        marker,
        isSelected: false,
        selectionOrder: null,
      })
    },
    async getDataStatus(punto) {
      const hasEditPermission = this.rolesAdmin.includes(this.user.role)

      let icono = null
      // SE PINTA GRIS SI NO TIENE DETALLE INICIAL
      if (!punto.detalleInicial) {
        this.contadores[0] += 1
        const data = {
          iconUrl: this.iconos.pendiente,
          action: () => {},
        }

        if (hasEditPermission) {
          data.action = () => this.levantamientoDatos(punto)
        }

        return data
      }
      // SE PINTA GRIS SI TIENE DETALLE INICIAL PERO NO ESTADO
      if (punto.detalleInicial && !punto.estado) {
        this.contadores[0] += 1
        const data = {
          iconUrl: this.iconos.pendiente,
          action: () => {},
        }

        if (hasEditPermission) {
          data.action = () => this.levantamientoDatos(punto)
        }
      }

      // SE PINTA NARANJA SI FUE INGRESADO MANUAL
      if (punto.ingresoManual) {
        this.contadores[1] += 1
        const data = {
          iconUrl: this.iconos.bloqueado,
          action: () => {},
        }

        if (hasEditPermission) {
          icono = this.iconos.bloqueado
          data.action = () => this.levantamientoDatos(punto)
        }
      }

      // SE PINTA MORADO SI FUE UN INGRESO ESPECIAL
      if (punto.detalleInicial && punto.detalleInicial.tipoInstalacion && punto.detalleInicial.tipoInstalacion.id === 2) {
        icono = this.iconos.especial
      }

      // SE PINTA UNA LETRA A SI YA FUE ASIGNADO
      if (punto.estado === 'ASIGNADA') {
        this.contadores[2] += 1
        const url = icono === this.iconos.especial ? this.iconos.asignadaPurple : this.iconos.asignadaGreen
        return {
          iconUrl: url,
        }
      }

      // SE PINTA VERDE SI YA FUE REALIZADO EL LEVANTAMIENTO (detalleInicial)
      if (punto.detalleInicial && punto.estado === 'INGRESADA') {
        const icon = icono || this.iconos.inspeccionado

        this.contadores[4] += 1
        let url = icon || this.iconos.inspeccionado
        const item = this.itemsSelected.find(m => m.punto.id === punto.id)

        if (item) {
          url = await this.createCustomIcon(
            icon,
            item.selectionOrder,
          )
        }

        let funcion
        if (this.enableAsignacion) {
          funcion = () => this.selectPunto(punto)
        } else if (!this.enableAsignacion) {
          this.itemsSelected = []
        }

        return {
          iconUrl: url,
          action: funcion,
        }
      }

      // SE PINTA AMARILLO SI ESTÁ EN INSTALACION
      if (punto.estado === 'INSTALACION') {
        this.contadores[3] += 1
        return {
          iconUrl: this.iconos.instalacion,
        }
      }

      // SE PINTA CAFE SI ESTÁ MIGRADO
      if (punto.estado === 'MIGRADA') {
        this.contadores[5] += 1
        return {
          iconUrl: this.iconos.migrada,
        }
      }

      this.contadores[6] += 1

      // SE SUPONE QUE ESTA YA FINALIZADO
      return {
        iconUrl: this.iconos.instalado,
      }
    },
    async levantamientoDatos(punto) {
      this.localidad = null

      if (punto.localidad) {
        this.localidad = this.localidades.find(
          localidad => localidad.id === punto.localidad,
        )
      }
      if (!punto.id) {
        this.puntoLevantamiento = JSON.parse(JSON.stringify(punto))
        this.showModalInspeccion = true
        return
      }

      this.puntoLevantamiento = JSON.parse(JSON.stringify(punto))
      this.showModalInspeccion = true
    },
    createCustomIcon(iconUrl, number) {
      const canvas = document.createElement('canvas')
      canvas.width = 40 // Ajusta según el tamaño de tu icono
      canvas.height = 40 // Ajusta según el tamaño de tu icono

      const context = canvas.getContext('2d')

      // Carga el icono base
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {
          // Dibuja el icono base
          context.drawImage(image, 0, 0, canvas.width, canvas.height)

          // Configura el estilo para el número
          context.fillStyle = 'white' // Color del texto
          context.strokeStyle = 'black'
          context.font = 'bold 14px Arial' // Tamaño y tipo de letra
          context.textAlign = 'center'
          context.textBaseline = 'middle'

          context.lineWidth = 3
          context.strokeText(
            number.toString(),
            canvas.width / 2,
            canvas.height / 2,
          )
          // Dibuja el número
          context.fillText(
            number.toString(),
            canvas.width / 2,
            canvas.height / 2,
          )

          // Convierte el canvas a una URL de imagen
          resolve(canvas.toDataURL())
        }
        image.onerror = reject
        image.src = iconUrl
      })
    },
    selectPunto(punto) {
      const indice = this.itemsSelected.findIndex(
        m => m.punto.id === punto.id,
      )
      if (indice === -1) {
        this.itemsSelected.push({
          punto: JSON.parse(JSON.stringify(punto)),
          selectionOrder: this.itemsSelected.length + 1,
        })
      } else {
        this.itemsSelected.splice(indice, 1)
      }

      this.itemsSelected.sort((a, b) => a.selectionOrder - b.selectionOrder)

      // Reasignar el orden de selección
      this.itemsSelected.forEach((item, index) => {
        // eslint-disable-next-line no-param-reassign
        item.selectionOrder = index + 1
      })

      this.updateMarkers(this.items)
    },
    updateMyLocation() {
      if (this.userLocation) {
        this.map.setCenter({
          lat: this.userLocation.lat,
          lng: this.userLocation.lng,
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90% !important;
  }
}

.map-container {
  margin-top: -15px;
  height: 50vh;
  min-height: 300px;
  width: 100%;
}

@media (max-width: 600px) {
  .map-container {
    height: 60vh;
  }
}

@media (min-width: 601px) {
  .map-container {
    height: 65vh;
  }
}
</style>
